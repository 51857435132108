<div class="bems-wrapper bems-login bems-wrapper-login">
  <div class="bems-container bems-login-container panel panel-default">
    <div class="bems-login-header panel-heading">BEMS Log In</div>
    <div class="bems-login-body panel-body">
      <form [formGroup]="loginForm" class="bems-login-form" (ngSubmit)="login()">
        <div class="form-group label-floating">
          <label class="control-label" for="bems-login-username">Username</label>
          <!-- <input id="userName" class="bems-login-username form-control" type='text' formControlName="username" placeholder="username"> -->
          <input id="userName" class="bems-login-username form-control" type='text' formControlName="username">
        </div>
        <div class="form-group label-floating">
          <label class="control-label" for="bems-login-password">Password</label>
          <!-- <input id="userName" class="form-control" type='password' formControlName="password" placeholder="password"> -->
          <input id="userName" class="form-control bems-login-password" type='password' formControlName="password">
        </div>
        <div class="alert alert-warning" role="alert" *ngIf="invalid === true">{{ msg }}</div>
        <button class="btn btn-primary btn-raised" type="submit">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
            <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
          </svg>
          <!-- <span class="bems-login-button-icon glyphicon glyphicon-log-in" aria-hidden="true"></span> -->
          <span class="bems-login-button-text">&nbsp; Log In</span>
        </button>
        <div class="form-group row">
          <div class="checkbox col-xs-12">
            <label>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customControlInline" formControlName="remember">
                <label class="custom-control-label" for="customControlInline">Remember</label>
              </div>
            </label>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-footer></app-footer>

