<div class="bems-footer pb-0">
    <div *ngIf="overviewData?.yellow != 0 && overviewData?.red != 0" class="bems-footer-container container-fluid">
        <span *ngIf="dataHeadTiltle=='STATUS'" class="bems-footer-text display-hide"><span>{{ dataHeadTiltle }}</span><br><span>{{ dataSubTitle }}</span></span>
        <span *ngIf="dataHeadTiltle!='STATUS'" class="bems-footer-text"><span>{{ dataHeadTiltle }}</span><br><span>{{ dataSubTitle }}</span></span>
        <div *ngIf="dataHeadTiltle=='STATUS'" class="bems-footer-progress-wrapper hidden-sm hidden-xs ng-hide" style="margin-top: 2rem;">
            <div style="color: white; font-size: large; font-weight: bolder;" class="d-none d-sm-block d-sm-none d-md-block">Energr Alarm Notification</div>
            <div class="bems-footer-tooltip-warning" [ngStyle]="{'margin-left':percent[0]+'%'}">{{percent[0] | number:0}}%</div>
            <div class="bems-footer-progress progress">
                <div class="progress-bar progress-bar-success" [ngStyle]="{'width':percent[0]+'%'}"></div>
                <div class="progress-bar progress-bar-warning" [ngStyle]="{'width':(percent[1]-percent[0])+'%'}" style="width: 0%;"></div>
                <div class="progress-bar progress-bar-danger" [ngStyle]="{'width':(100-percent[1])+'%'}" style="width: 100%;"></div>
            </div>
            <div class="bems-footer-tooltip-danger" [ngStyle]="{'margin-left':percent[1]+'%'}">{{percent[1] | number:0}}%</div>
        </div>
        <p *ngIf="dataHeadTiltle=='STATUS'" class="bems-footer-progress-text hidden-sm hidden-xs ng-hide" style="margin-top: 2rem;">
            <span class="title">Total Energy Target</span>
            <br>
            <span class="value">{{ overviewData?.total | number:'1.1-1' }} </span>
            <span class="unit">kWh</span>
        </p>
        <div *ngIf="dataHeadTiltle=='DASHBOARD'" class="bems-footer-energy d-none d-sm-block d-sm-none d-md-block">
            Based on energy quota <strong>{{BaseEnergy?.energy_quota | number:'1.1-1'}}</strong> kWh
        </div>
        <div class="clearfix"></div>
    </div>
    <div id="footer-b-outter" class="hidden-xs hidden-sm container-fluid" style="display: inline-block">Copyright 2018 , Chulalongkorn University Building Energy Management System</div>
</div>