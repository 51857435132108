import { Injectable } from '@angular/core';
import { httpOptions } from './../header-options/header-option';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  httpOptionsToken() {
    httpOptions.headers = httpOptions.headers.set('token', localStorage.getItem('ustk'));
    return httpOptions;
  }

  
}
