import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';

const authRouters: Routes = [
  { path: 'login', component: LoginComponent, data: {headTitle: 'LOGIN',subTitle: ''}}
];


@NgModule({
  imports: [RouterModule.forChild(authRouters)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
