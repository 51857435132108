import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageDialogService {

  constructor() { }
  msgInputText(message?: string) {
    const msg = (message || 'Please fill in the information');
    return msg;
  }
  
  msgConfrimUpdate(message?: string) {
    const msg = (message || 'Do you want to update');
    return msg;
  }
}
