import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

import { ConstService } from "../service/const.service";

import { FooterService } from './footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {
  dataHeadTiltle = '';
  dataSubTitle = '';
  constructor(
    private router: Router,
    private footerServ: FooterService,
    private activatedRoute: ActivatedRoute,
    private constServ: ConstService) {
      this.dataHeadTiltle = this.routerInit();
      this.dataSubTitle = this.routerInit();
  }

  ngOnInit(): void {
    this.getProfile();
    this.getDashboard();
  }

  overviewData;
  percent = [];
  getProfile() {
    this.footerServ.getEnergyProfile().subscribe(
      (res:any) => {
        this.overviewData = res;
        this.overviewData = this.overviewData?.quota?.en;
        this.percent.push(Math.round((this.overviewData?.yellow*100)/this.constServ.forDiv(this.overviewData?.total)));
        this.percent.push(Math.round((this.overviewData?.red*100)/this.constServ.forDiv(this.overviewData?.total)));
      });
  }

  routerInit(): any{
    this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    )
    .subscribe(() => {

      var rt = this.getChild(this.activatedRoute)

      rt.data.subscribe(data => {
        this.dataHeadTiltle = data.headTitle;
        this.dataSubTitle = data.subTitle;
      })
    })
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  boards
  BaseEnergy
  getDashboard() {
    this.footerServ.getEnergyDashboard().subscribe(
      (res:any) => {
        this.boards = res;
        this.BaseEnergy = this.boards[9];
      }
    );
  }

}
