/* External Dependencies */
import { Injectable } from '@angular/core';
import { HttpHeaders,HttpClient } from '@angular/common/http';
import { Observable, of, throwError} from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { tap, delay } from 'rxjs/operators';
import { Router } from '@angular/router';


/* Internal Dependencies */
import { CoreService, StorageService, ConfigService } from './../../app/service';

export interface authen {
  gid:number;
  token:string;
  uid:number;
  valid:boolean;
};
export interface key {
  bsid: string;
  pk: number[];
};

declare var RSAKey: any;

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private core: CoreService,
    private storag: StorageService,
    private router: Router
  ) { }

  rsa = new RSAKey();
  key:any = {};
  passpharse;
  userNotFound:boolean = false;
  

  loginServ(encrypt) {
    let url = this.core.getApiUrl(`/security/login/${encrypt}`);
    return this.http.get<authen>(url);
  }
  
  rsaGetServ() {
    let url = this.core.getApiUrl('/security/getkey');
    return this.http.get<key>(url);
  }

  setKey(username:string,password:string,remember:boolean){
    this.rsaGetServ().subscribe((key) => {
      this.rsa.setPublic(key.pk[0],key.pk[1]);
      this.passpharse = this.rsa.encrypt(JSON.stringify({user: username, pass: password, remember: remember, bsid: key.bsid}));
      this.authen(username,password,remember)
    });
  }

  authen(username:string,password:string,remember:boolean) {
    this.loginServ(this.passpharse).subscribe((authen)=>{
      if(authen.valid){
        this.authorize(username,remember,authen.uid,authen.gid,authen.token);
      }
    });
  }


  authorize(username,remember,id,type,token) {
    this.storag.setSession(username,id,type,token);
    this.storag.setLocal();
    // console.log("Router:",this.router)
    this.router.navigate(['/home']);
  }

  logoutServ(id, type): any {
    let url = this.core.getApiUrl(`/security/logout/${id}/${type}`);
    this.http.get(url);
  }

  deauthen() {
    this.logoutServ(sessionStorage.getItem('usid'), sessionStorage.getItem('usty'));
    this.storag.clears();
    this.router.navigate(['/login']);
   }



  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, ` +`body was: ${error.error}`)
    }
    // Return an observable with a user-facing error message.
    return throwError( 'Something bad happened; please try again later.');
  }  
  



}
