<div class="container bg-login">
    <div class="row">
        <div class="col-12">
            <div class="card mx-auto card-w" *ngIf="!hide_login">
                <div class="card-body">
                    <img src="assets/images/logo.png" class="rounded mx-auto d-block">
                    <form [formGroup]="loginForm" (ngSubmit)="loginLocal()">
                        <div class="form-group">
                            <label for="exampleInputEmail1"><strong>Username</strong></label>
                            <input type="text" id="userName" class="form-control" type='text' formControlName="username" placeholder="username">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1"><strong>Password</strong></label>
                            <input id="userName" class="form-control" type='password' formControlName="password" placeholder="password">
                            <div class="alert alert-warning mt-2" role="alert" *ngIf="invalid === true">{{ msg }}</div>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1" formControlName="remember">
                            <label class="form-check-label" for="exampleCheck1">Remember</label>
                        </div>
                        <button type="submit" class="btn btn-primary mt-2">
                            <span class="material-icons align-middle"> login </span>
                            <span> Login</span>
                        </button>
                    </form>
                    <!-- <a routerLink="/owner"> -->
                    <button type="submit" (click)="aeroBaseLogin()" class="btn btn-primary w-100 mt-4">Log in with LDAP or LINE</button>
                    <!-- </a> -->
                </div>
            </div>

        </div>
    </div>
</div>