import { Injectable } from '@angular/core';

import { HttpHeaders,HttpClient } from '@angular/common/http';
import { CoreService, ConfigService } from '../service/index';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private core: CoreService,
  ) { }

  getEnergyProfile(){
    let url = this.core.getApiUrl(`/profile/overview_profile`);
    return this.http.get(url);
  }

  getEnergyDashboard(){
    let url = this.core.getApiUrl(`/profile/overview_dashboard`);
    return this.http.get(url);
  }
}
