import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OrderModule } from 'ngx-order-pipe';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';

import { HeaderComponent } from './../header/header.component';
import { FooterComponent } from './../footer/footer.component';

const materialModules = [
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatCardModule,
  MatInputModule,
  MatTabsModule,
  MatTableModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatRadioModule,
  MatProgressBarModule,
  MatRadioModule,
  MatTreeModule,
  MatButtonToggleModule,
  MatGridListModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatSelectModule,
  MatDividerModule,
  MatSlideToggleModule,
  MatSortModule,
];

@NgModule({
  declarations: [HeaderComponent,FooterComponent],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    Ng2SearchPipeModule,
    OrderModule,
    ...materialModules
  ],
  exports: [
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    Ng2SearchPipeModule,
    OrderModule,
    ...materialModules,
    HeaderComponent,
    FooterComponent
  ]
})

export class SharedModule { }
