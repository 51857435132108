import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstService {

  constructor() { }

  month:string[] = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN","JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  fullMonth:string[] = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
  colors:string[] = ['#bc250c','#f5c922','#9edf48','#f44336','#ffc107','#03a9f4','#59b75c'];
  sColors:string[] = ['#a200ff','#ff7f00','#a65628','#f781bf','#999999','#a6cee3','#1f78b4','#b2df8a','#fb9a99','#fdbf6f','#ff7f00','#cab2d6','#e41a1c','#ffff33','#377eb8','#4daf4a'];
 
  getMonths() {return this.month;}
  getMonth(num) {return this.month[num];}
  getFullMonths() {return this.fullMonth;}
  getFullMonth (num) {return this.fullMonth[num];}
  getColor (color) {switch(color){
    case 'red': return this.colors[0];
    case 'yellow': return this.colors[1];
    case 'green': return this.colors[2];
    case 'm-red': return this.colors[3];
    case 'm-orange': return this.colors[4];
    case 'm-blue': return this.colors[5];
    case 'm-green': return this.colors[6];
    default: return '#000';
  }}
  
  getSColor(num) {return this.sColors[num];}

  forDiv(dataDiv) { return Number(dataDiv) + 0.0000000001; }
  
}
