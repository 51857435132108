import { Injectable } from '@angular/core';

import { HttpHeaders,HttpClient } from '@angular/common/http';
import { CoreService, ConfigService } from '../service/index';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private core: CoreService,
  ) { }

  setFavorite(data) {
    let url = this.core.getApiUrl(`/users/favorite?path=${data}`);
    return this.http.put(url,data);
  }
}
