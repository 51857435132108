/* External Dependencies */
import { Injectable } from '@angular/core';
import { HttpHeaders,HttpClient } from '@angular/common/http';
import { Observable, of, throwError} from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { tap, delay } from 'rxjs/operators';
import { Router } from '@angular/router';


/* Internal Dependencies */
import { CoreService, StorageService, ConfigService } from '../service';

export interface authen {
  gid:number;
  token:string;
  uid:number;
  valid:boolean;
};
export interface key {
  bsid: string;
  pk: number[];
};

declare var RSAKey: any;

@Injectable({
  providedIn: 'root'
})

export class AuthLocalService {

  constructor(
    private http: HttpClient,
    private core: CoreService,
    private storag: StorageService,
    private router: Router
  ) { }

  rsa = new RSAKey();
  key:any = {};
  passpharse;
  userNotFound:boolean = false;
  

  public loginLocalServ(encrypt) {
    let url = this.core.getApiUrl(`/security/login/${encrypt}`);
    return this.http.get<authen>(url);
  }

  public logOutServ(id, type): any {
    let url = this.core.getApiUrl(`/security/logout/${id}/${type}`);
    this.http.get(url);
  }
  
  public rsaGetServ() {
    let url = this.core.getApiUrl('/security/getkey');
    return this.http.get<key>(url);
  }

  // public setKey(username:string,password:string,remember:boolean){
  //   this.rsaGetServ().subscribe((key) => {
  //     this.rsa.setPublic(key.pk[0],key.pk[1]);
  //     this.passpharse = this.rsa.encrypt(JSON.stringify({user: username, pass: password, remember: remember, bsid: key.bsid}));
  //     this.authen(username,password,remember)
  //   });
  // }

  // public authen(username:string,password:string,remember:boolean) {
  //   this.loginLocalServ(this.passpharse).subscribe((authen)=>{
  //     if(authen.valid){
  //       this.authorize(username,remember,authen.uid,authen.gid,authen.token);
  //     }
  //   });
  // }

  public authorize(username,remember,id,type,token) {
    localStorage.setItem('guard_type', 'guard-local');    
    let redirect_url = localStorage.getItem("redirect_url");
    this.storag.setSession(username,id,type,token);
    this.storag.setLocal();

    if(redirect_url == 'undefined'){
      this.router.navigate(['/home']);
    }else{
      this.router.navigate(['/home/'+redirect_url]);
    }
  }
  

  public deauthen() {
    this.logOutServ(sessionStorage.getItem('usid'), sessionStorage.getItem('usty'));
    this.storag.clears();;
    this.router.navigate(['/home']);
  }

}
